
import { defineComponent, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { StaffAttendanceService } from "@/services/staffattendance/StaffAttendanceService";
import Utils from "@/utils/Utils";
import StaticsticsMenu from "@/views/attendance/statistics/staffattendance/StaticsticsMenu.vue";
import { menuController } from "@ionic/vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
export default defineComponent({
  name: "AttendanceStatistics",
  components: {
    Header,
    SearchBar,
    StaticsticsMenu,
    CardLoading,
  },

  ionViewWillEnter() {
    this.initData();
  },
  setup() {
    const query = useRoute().query;
    const btnSizeRight = reactive(["38px"]);
    const btnIconRight = reactive(["iconshaixuan"]);

    const staffAttendanceService = new StaffAttendanceService();

    const recordList = reactive({ data: new Array() });

    const router = useRouter();

    const page: number = 1;
    const size: number = 10;
    const searchkey: string = "";
    const startTime: string = "";
    const endTime: string = "";

    let isList = ref(true);

    let that = this;
    let orgId = "";
    let params = {
      page: page,
      size: size,
      startDate: startTime,
      endDate: endTime,
      keyword: searchkey,
      status: "",
      orgId: orgId,
    };

    const initData = () => {
      resetPage();
    };

    //重新从第一页获取
    const resetPage = () => {
      isList.value = true;
      params.page = 1;
      getListRequest((res: any) => {
        recordList.data = res;
      });
    };
    const nextPage = (event: any) => {
      params.page++;
      getListRequest(
        (result: any) => {
          isList.value = false;
          recordList.data = recordList.data.concat(result);
          setTimeout(() => {
            event.target.complete();
          }, 1000);
          if (result) {
            if (result == null || result.length <= 0) {
              //  event.target.disabled = true;
            }
          }
        },
        () => {
          isList.value = false;
          event.target.complete();
        }
      );
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };

    const getListRequest = (callback: any, errCalback?: any) => {
      staffAttendanceService
        .getAllAttendanceList(params)
        .then((res: any) => {
          callback(res);
        })
        .catch(() => {
          if (errCalback) {
            errCalback();
          }
        });
    };

    const openFilter = (r: any) => {
      if (r == "0_icon") {
        menuController.enable(true, "StaticsticsMenu");
        menuController.open("StaticsticsMenu");
      }
    };

    const filterHandler = (data: any) => {
      menuController.close();
      // checkIndex.value = 0;
      // searchParams.workflowName = data.name;
      // searchParams.approvalStatus = data.status;
      // searchParams.startDate = data.dateStart;
      // searchParams.endDate = data.dateEnd;
      resetPage();

      // searchParams.startDateEnd=data.,
      // searchParams.endDateStart=data.,
      // searchParams.endDateEnd=data.,
      // initDate(r);
    };

    const searchHandler = (searchParam: any) => {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }

      params.keyword = searchParam.searchKey;
      resetPage();
    };

    const subStringUserName = (userName: string) => {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    };

    const gotoDetail = (userId: any, userName: any) => {
      router.push({
        path: "/AttendanceStatisticsDetails",
        query: {
          userId: userId,
          userName: userName,
        },
      });
    };

    return {
      query,
      btnSizeRight,
      btnIconRight,
      openFilter,
      nextPage,
      doRefresh,
      getListRequest,
      recordList,
      initData,
      subStringUserName,
      gotoDetail,
      searchHandler,
      filterHandler,
      isList,
    };
  },
});
